import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import Link from "src/components/PartnerLink";
import QuotationDetail from "./QuotationDetail";

/**
 * 이용신청 화면
 */
const QuotationVerifyPage = () => {
  return (
    <>
      <MetaTag title="이용신청 | TaapSpace" />
      <Header headerType="BACK" title={"예약하기"} rightArea={<Link to="/court/main" className="home"></Link>} />
      <QuotationDetail />
    </>
  );
};

export default QuotationVerifyPage;
