import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { ClosedDay, ScheduleData } from "src/api/reservation/reservation-type";
import { ConfirmModal } from "src/components";
import SwiperCalendar from "src/components/swiperCalendar/SwiperCalendar";
import { Modal } from "src/pages/visitor/visitor-types";
import { numberToStringWithComma } from "src/utils/common-util";

type Props = {
  date: string;
  selectedDate: (date: string) => void;
  scheduleSlots: ScheduleData[];
  onSelectSchedules: (data: ScheduleData) => void;
  selectedSchedule: ScheduleData[];
  fetchClosedList: (startDate: string, endDate: string) => void;
  closedDays: ClosedDay[];
  onResetSchedule: () => void;
  validateText: string;
};

const FacilityReservation = ({
  date,
  selectedDate,
  scheduleSlots,
  onSelectSchedules,
  selectedSchedule,
  fetchClosedList,
  closedDays,
  onResetSchedule,
  validateText,
}: Props) => {
  const slotRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLInputElement>; //포커스를 위한 ref
  const calendarRef = useRef<HTMLDivElement>(null);

  // 시간 설정 Slot에서 drag 로 스크롤 이동 적용
  const { events } = useDraggable(slotRef); // Now we pass the reference to the useDraggable hook:

  // 스케줄 예약 최소/최대 시간 확인 api

  //유저의 최소/최대 사용시간 노출

  useEffect(() => {
    scrollToCurrentSlot();
  }, [scheduleSlots]);

  // 현재 시간
  const calendarDate = useMemo(() => {
    const todayAndTime = moment().toDate();
    const _calendarDate = moment(date).toDate();

    if (moment(todayAndTime).isSame(_calendarDate, "D")) {
      return todayAndTime;
    } else {
      return _calendarDate;
    }
  }, [date]);

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const scrollToCurrentSlot = () => {
    if (slotRef.current) {
      const findTimeRow = scheduleSlots.find(
        (item) =>
          moment(item.startDate).isBefore(calendarDate) && //
          moment(item.endDate).isAfter(calendarDate),
      )?.slot;

      //findTimeRow 는 오늘 날짜, 현재시간 기준의 slot 번호 / 17은 오늘 이후 날짜로 오전 8시 기준 슬롯 번호
      const currentWidth = (Number(findTimeRow ? findTimeRow : 17) - 1) * 46.99;
      const friendsContainer = slotRef.current;

      if (friendsContainer) {
        friendsContainer.scrollTo({
          top: 0,
          left: currentWidth,
          behavior: "smooth",
        });
      }
    }
  };

  // 현재 시간 기준으로 스크롤 이동

  const reservationHours = useMemo(() => {
    const list = scheduleSlots.map((item) => moment(item.startDate).format("HH"));
    const uniqueData: string[] = [];
    const seenIndices: Record<string, number> = {};

    for (let i = 0; i < list.length; i++) {
      const value: string = list[i];

      if (seenIndices[value] === undefined) {
        // 해당 값이 처음 나타난 경우
        seenIndices[value] = i;
        uniqueData.push(value);
      } else {
        // 이미 나온 값이라면 현재 값은 ""(빈 문자열)으로 처리
        uniqueData[i] = "";
      }
    }
    return uniqueData;
  }, [scheduleSlots]);

  const onClickTimes = (schedule: ScheduleData) => {
    if (schedule.isClosed) return;

    const isAvailable =
      (moment(schedule.startDate).isBefore(calendarDate) && moment(schedule.endDate).isAfter(calendarDate)) ||
      moment(schedule.startDate).isSameOrAfter(calendarDate);

    if (!isAvailable) {
      setAlertModal({ isOpen: true, message: "과거 시간을 선택할 수 없습니다" });
      return;
    }

    onSelectSchedules(schedule);
  };

  return (
    <section className="facility-info__calendar-time">
      <div
        ref={calendarRef}
        className="calendar-area" //
      >
        <h2>날짜를 선택해 주세요</h2>
        <SwiperCalendar
          fetchClosedList={fetchClosedList}
          onDateClick={(date) => {
            selectedDate(date);
            onResetSchedule();
          }}
          closedDays={closedDays}
        />
      </div>
      <div className="time-area">
        <div className="flex-center">
          <h2 className="pl0 pr5">시간을 선택해 주세요</h2>
          <p className="font12 text-primary4">{`(좌우로 넘겨 시간을 선택하세요)`}</p>
        </div>
        <div
          className="time-picker" //
          ref={slotRef} // 스크롤 최상단 위치, 시간 선택 부분만 스크롤 할거면 여기 사용
          {...events}
        >
          <div className="time-picker__index">
            {reservationHours.map((hour, idx) => (
              <span
                key={idx} //
                className={`ml3 ${Number(moment(calendarDate).format("HH")) > Number(hour) ? "text-primary4" : ""}`}
              >
                {hour}
              </span>
            ))}
          </div>
          <div className="time-picker__index">
            {reservationHours.map((hour, idx) => (
              <div key={idx}>
                {/* <span key={idx} className={Number(moment(calendarDate).format("HH")) > Number(hour) ? "text-primary4" : ""}>
                  {hour}
                </span> */}
              </div>
            ))}
          </div>
          <div id="time-picker__grid" className="time-picker__grid">
            {scheduleSlots.map((item, idx) => (
              <div
                className={
                  selectedSchedule.find((time) => time.slot === item.slot)
                    ? "--active"
                    : item.isClosed === true //
                    ? "--disabled"
                    : ""
                }
                key={item.slot}
                onClick={() => {
                  onClickTimes(item);
                }}
              >
                {!item.isClosed && numberToStringWithComma(item.price)}
              </div>
            ))}
          </div>
        </div>
        {validateText && <p className="mt10 font13 text-red">{validateText}</p>}
      </div>
      <>
        {alertModal.isOpen && (
          <ConfirmModal isOpen={true} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
            <p className="font18 font-weight-600">{alertModal.message}</p>
          </ConfirmModal>
        )}
      </>
    </section>
  );
};

export default FacilityReservation;
