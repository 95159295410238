import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getExpenseProofAsync, postExpenseProofAsync } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import Header from "src/components/layout/Header";
import { Modal } from "src/pages/contract/contract-types";
import TaxInvoiceInfo from "./switchInvoice/TaxInvoiceInfo";
import { emailFormat, linePhoneFormat, YmdFormat } from "src/utils/common-util";
import moment from "moment";
import { ConfirmModal } from "src/components";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";

const TaxInvoiceInfoEdit = () => {
  const { contractId } = useParams();
  const navigate = usePartnerNavigate();

  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
    message: "",
    subMessage: "",
  });
  // 지출 증빙 자료 조회
  const { executeAsync: getExpenseProof } = useApiOperation(getExpenseProofAsync);
  // 지출 증빙 자료 저장
  const { executeAsync: saveExpenseProof } = useApiOperation(postExpenseProofAsync, { noHandleError: true, anotherHandleError: true });
  const [documentProofRequest, setDocumentProofRequest] = useState<any>({
    identityNum: "",
    mediaList: [],
    taxInvoiceEmail: "",
    taxInvoicePhone: "",
    taxInvoiceDate: null,
  });

  const errorInit: any = useMemo(() => {
    return {
      error: {
        identityNum: false,
        identityType: false,
        mediaList: false,
        taxInvoiceEmail: false,
        taxInvoicePhone: false,
        taxInvoiceDate: false,
      },
      format: {
        identityNum: false,
        identityType: false,
        mediaList: false,
        taxInvoiceEmail: false,
        taxInvoicePhone: false,
        taxInvoiceDate: false,
      },
    };
  }, []);
  const [proofError, setProofError] = useState<any>(_.cloneDeep(errorInit));
  //세금계싼서 벨리데이션 체크
  const validationChecker = useCallback(
    (documentProofRequest: any) => {
      let rtnFlag = true;
      const check = _.cloneDeep(errorInit);
      // console.log("documentProofRequest.expenseProofType", documentProofRequest);
      if (documentProofRequest.expenseProofType === "TAX_INVOICE") {
        if (documentProofRequest.identityNum === null || documentProofRequest.identityNum === "") {
          // console.log("identityNum");
          rtnFlag = false;
          check.error.identityNum = true;
          check.format.identityNum = false;
        } else {
          // console.log(
          //   "documentProofRequest.identityNum",
          //   documentProofRequest.identityNum,
          //   documentProofRequest.identityNum.length,
          //   documentProofRequest.identityNum.length === 10,
          // );
          check.error.identityNum = false;
          if (documentProofRequest.identityNum.length === 10) {
            console.log("정상");
            check.format.identityNum = false;
            check.error.identityNum = false;
          } else {
            console.log("비정상");
            check.format.identityNum = true;
            check.error.identityNum = false;
            rtnFlag = false;
          }
        }
        // console.log("rtnFlag identityNum", rtnFlag);
        if (documentProofRequest.mediaList.length === 0) {
          // console.log("mediaList");
          rtnFlag = false;
          check.error.mediaList = true;
        } else {
          check.error.mediaList = false;
        }
        if (documentProofRequest.taxInvoiceEmail === null || documentProofRequest.taxInvoiceEmail === "") {
          // console.log("taxInvoiceEmail");
          rtnFlag = false;
          check.error.taxInvoiceEmail = true;
        } else {
          check.error.taxInvoiceEmail = false;
          if (!emailFormat(documentProofRequest.taxInvoiceEmail)) {
            rtnFlag = false;
            check.format.taxInvoiceEmail = true;
          } else {
            check.format.taxInvoiceEmail = false;
          }
        }
        // console.log("rtnFlag taxInvoiceEmail", rtnFlag);
        if (documentProofRequest.taxInvoicePhone === null || documentProofRequest.taxInvoicePhone === "") {
          // console.log("taxInvoicePhone");
          rtnFlag = false;
          check.error.taxInvoicePhone = true;
        } else {
          check.error.taxInvoicePhone = false;
          if (!linePhoneFormat(documentProofRequest.taxInvoicePhone)) {
            rtnFlag = false;
            check.format.taxInvoicePhone = true;
          } else {
            check.format.taxInvoicePhone = false;
          }
        }
        // console.log("rtnFlag taxInvoicePhone", rtnFlag);
        if (documentProofRequest.taxInvoiceDate === null || documentProofRequest.taxInvoiceDate === "") {
          // console.log("taxInvoiceDate");
          rtnFlag = false;
          check.error.taxInvoiceDate = true;
        } else {
          check.error.taxInvoiceDate = false;
        }
        // console.log("rtnFlag taxInvoiceDate", rtnFlag);
      }

      setProofError({ ...check });
      return rtnFlag;
    },
    [errorInit, setProofError],
  );
  const handleClickExpenseProofTaxInvoice = useCallback(
    async (handleClickExpenseProof: any) => {
      const checkData = _.cloneDeep(handleClickExpenseProof);
      const result = validationChecker(handleClickExpenseProof);
      if (result === true) {
        if (checkData.taxInvoiceDate !== null && checkData.taxInvoiceDate !== "") {
          checkData.taxInvoiceDate = moment(checkData.taxInvoiceDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
        }

        if (checkData.contractId !== null && Number(checkData.contractId) > 0) {
          checkData.cmdType = "U";
        } else {
          checkData.cmdType = "C";
        }

        if (checkData.mediaList.length > 0) {
          checkData.mediaList.forEach((media: any) => {
            // console.log("media.blobUrl", media.blobUrl);
            delete media.blobUrl;
          });
        }
      }

      const saveExpense = await saveExpenseProof({ contractId: String(contractId), expenseProofRequest: checkData });
      if (saveExpense.status > 199 && saveExpense.status < 400) {
        setAlertModal({
          ...alertModal,
          isOpen: true,
          message: "세금계산서 발행 정보를 수정하였습니다.",
          // subMessage: "요청하신 정보로 현금영수증 발행이 접수되었습니다.",
        });
        // onClose();
      } else {
        setAlertModal({
          ...alertModal,
          isOpen: true,
          message: "이미 발행되어 수정할 수 없습니다. 변경이 필요하면 문의해 주세요. ",
        });
      }
    },
    [validationChecker, saveExpenseProof, contractId, alertModal],
  );
  // 신청/계약 상세 조회
  const fetchContractProof = useCallback(
    async (contractId: string) => {
      const { data: data2 } = await getExpenseProof({ contractId });

      if (data2.data.expenseProofRequest.mediaList !== null) {
        data2.data.expenseProofRequest.mediaList.forEach((dt: any) => {
          dt["cmdType"] = "U";
        });
        setDocumentProofRequest(data2.data.expenseProofRequest);
      }
    },
    [getExpenseProof],
  );
  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (contractId) {
      fetchContractProof(contractId);
    }
  }, [fetchContractProof, contractId]);
  const isMediaListEmpty = useMemo(() => {
    let rtn = false;
    const mlist = documentProofRequest.mediaList.filter((media: any) => media.isDeleted === false && media.cmdType !== "D");
    if (mlist.length === 0) {
      rtn = true;
    }
    return rtn;
  }, [documentProofRequest]);
  const isDisabled = useMemo(() => {
    let rtn = false;

    if (
      documentProofRequest.identityType === "" ||
      documentProofRequest.identityType === null ||
      documentProofRequest.identityNum === null ||
      documentProofRequest.identityNum === "" ||
      documentProofRequest.taxInvoiceEmail === null ||
      documentProofRequest.taxInvoiceEmail === "" ||
      documentProofRequest.taxInvoicePhone === null ||
      documentProofRequest.taxInvoicePhone === "" ||
      documentProofRequest.taxInvoiceDate === null ||
      isMediaListEmpty ||
      !validationChecker(documentProofRequest)
    ) {
      rtn = true;
    }

    return rtn;
  }, [documentProofRequest, isMediaListEmpty, validationChecker]);

  return (
    <>
      <Header headerType="CLOSE" title="세금계산서 발급 정보" onClickCloseButton={onClose} />

      <div className="base-section-layout flex-row s2-payment-info info-page">
        <TaxInvoiceInfo
          mode="edit"
          documentProofRequest={documentProofRequest}
          setDocumentProofRequest={setDocumentProofRequest}
          proofError={proofError}
          setProofError={setProofError}
        />
      </div>
      <div className="base-floating-btn-wrap">
        <div className="container">
          <button
            type="button"
            className="base-btn primary-btn"
            disabled={isDisabled}
            onClick={() => handleClickExpenseProofTaxInvoice(documentProofRequest)}
          >
            확인
          </button>
        </div>
      </div>
      {alertModal.isOpen && (
        <ConfirmModal
          isOpen={alertModal.isOpen}
          btnRightTitle={"확인"}
          onClick={() => {
            setAlertModal({ ...alertModal, isOpen: false });
            onClose();
            // window.location.reload();
          }}
        >
          <div>
            <p className="font18 font-weight-600">{alertModal?.message}</p>
            <p className="font18">{alertModal?.subMessage}</p>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default TaxInvoiceInfoEdit;
