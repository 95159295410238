import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeList } from "./routes";

// prod 에서만 오픈하는 페이지를 관리하기 위한 환경변수
const isOnlyServePage = process.env.REACT_APP_ONLY_SERVE_PAGE;

const RoutesList = () => {
  return (
    <Routes>
      {routeList
        .filter((route) => {
          if (route?.isDisabledProd) {
            // prod 에서는 노출시키지 않는 옵션 적용
            return isOnlyServePage ? false : true;
          } else {
            return true;
          }
        })
        .map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.isProtectRoute ? <ProtectedRoute component={route.component} /> : <route.component />}
          />
        ))}

      {/* page not found */}
      <Route path="*" element={<Navigate to="/court/error/page-not-found" replace></Navigate>} />
    </Routes>
  );
};

export default RoutesList;
