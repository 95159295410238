import { useState } from "react";
import Link from "src/components/PartnerLink";

/**
  layout > footer 컴포넌트
 */

export default function Footer() {
  const [isOpen, setIsOpen] = useState<Boolean>(false);

  return (
    <footer className="footer-mo">
      <div className="box">
        <ul>
          <li>
            {/* 회사소개 */}
            <a href="https://www.pnpt.kr/" target="_blank" rel="noreferrer">
              회사소개
            </a>
          </li>
          <li>
            {/* 이용약관 */}
            <a href="https://guidelines.taapspace.kr/3c06b12a-68e6-4275-ab52-ab0f9f080b1a" target="_blank" rel="noreferrer">
              서비스 이용약관
            </a>
          </li>
          <li>
            {/* <b>개인정보처리방침</b> */}
            <a href="https://guidelines.taapspace.kr/30c9aa8e-488a-4661-bd88-2629f120e263" target="_blank" rel="noreferrer">
              <b>개인정보처리방침</b>
            </a>
          </li>
          <li>
            {/* 운영정책 */}
            <a href="https://guidelines.taapspace.kr/127133c5-cc34-478e-af2c-338774a198e4" target="_blank" rel="noreferrer">
              코트 이용약관
            </a>
          </li>
        </ul>
      </div>
      <section>
        <button className={`company-toggle ${isOpen ? "icon-down" : "icon-up"}`} onClick={() => setIsOpen(!isOpen)}>
          (주) 핀포인트 사업자 정보
        </button>
        {isOpen && (
          <div className="company-info">
            <p>
              (주)핀포인트
              <br />
              서울 강남구 압구정로8길 26
              <br /> 대표 : 안진혁
              <br /> 사업자등록번호 : 358-86-01066
              <br />
              통신판매업신고번호 : 2022-서울강남-04793
              <br /> 고객센터 : 02-3442-6610 (주중 09:00~06:00, 공휴일 휴무) <br />
              이메일 : contact@ppoint.kr
            </p>
          </div>
        )}
      </section>
    </footer>
  );
}
