import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiResponse } from "../public-types";
import { ProductListModel, ProductListParams, ProductModel } from "./product-types";

/* 
  TPP01. (무인증) 공간상품 정보 조회
*/
export async function getPublicProductAsync(
  axios: AxiosInstance,
  params?: {
    productId: string | number;
  },
): Promise<AxiosResponse<ApiResponse<{ content: ProductModel }>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  return await axios.get(`/api/court/pr/public/products/${params.productId}`);
}

/* 
  TPP01. (인증) 공간상품 정보 조회
*/
export async function getProductAsync(
  axios: AxiosInstance,
  params?: {
    productId: string | number;
  },
): Promise<AxiosResponse<ApiResponse<{ content: ProductModel }>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  return await axios.get(`/api/court/pr/products/${params.productId}`);
}

/*
  TPP06. (무인증) 공간상품 기본정보 목록
*/
export async function getProductListAsync(
  axios: AxiosInstance,
  params?: ProductListParams,
): Promise<AxiosResponse<ApiResponse<{ content: ProductListModel[] }>>> {
  if (!params) {
    throw Error("params not found");
  }
  return await axios.get(`/api/court/pr/public/products`, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}

/*
  TPP08. (무인증) 공개된 상품 목록
*/
export async function getSearchProductListAsync(
  axios: AxiosInstance,
  params?: ProductListParams,
): Promise<AxiosResponse<ApiResponse<{ content: ProductListModel[] }>>> {
  if (!params) {
    throw Error("params not found");
  }
  return await axios.get(`/api/court/pr/public/products/search`, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
}
