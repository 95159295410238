import { useEffect } from "react";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import { useCourtAuth } from "src/components/court-auth";
import { useMemberInfoState } from "src/recoil/member/hook";

/**
 * 로그인 화면
 */
const Login = () => {
  const navigate = usePartnerNavigate();
  const { login } = useMemberInfoState();
  const { security } = useCourtAuth();

  useEffect(() => {
    // if (security.status === "authenticated") {
    //   // 로그인 되어있으면 신청/계약 목록 화면으로 이동
    //   // navigate(route.mainPath);
    // } else {
    //   login();
    // }
    login();
  }, [security, navigate]);

  return (
    <>
      {/* <MetaTag title="로그인 | TaapSpace" imgsrc={`${process.env.REACT_APP_S3_CONTENT_BASEURL}court/taapspace_logo_og.png`} />
      <Header rightDisabled={true} />
      <div className="my-page">
        <article className="before-login-contents">
          <h1>로그인</h1>
          <p>
            플레이하실 준비가 되셨나요?
            <br />
            로그인이 필요한 페이지입니다.
            <br />
            비회원도 '시작하기'로 회원가입 후 이용 가능합니다.
          </p>
          <BaseButton className="login-btn" title="시작하기" onClick={() => login()} />
        </article>
      </div> */}
    </>
  );
};
export default Login;
